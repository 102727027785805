import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/feiten-over-diarree/gezonde-eetgewoonten/recepten-voor-een-evenwichtige-voeding/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="f1fba15d4905cfe6eb84a2e4b3ec02194864e1b4"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/la-diarrhee/">
                  {" "}
                  Des habitudes alimentaires saines{" "}
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils pour le traitement des symptomes de la diarrhée
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                  Causes de la diarrhée
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/stress-et-diarrhee/">
                  Stress et diarrhée
                </a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/emploi-du-temps-charge/">
                      Emploi du temps chargé
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/diarrhee-examens/">
                      Faire face aux examens
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/fr/la-diarrhee/infection-virale-ou-bacterienne/">
                  Infection virale ou bactérienne
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-pendant-les-regles/">
                  La diarrhée pendant les règles
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-chronique/">
                  La diarrhée chronique
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/consultez-votre-medecin/">
                  Consulter un médecin?
                </a>
              </li>
              <li className="parent-active last">
                <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/">
                  Des habitudes alimentaires saines
                </a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/allergies-alimentaires/">
                      Allergies alimentaires{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/intoxication-alimentaire/">
                      Intoxication alimentaire
                    </a>
                  </li>
                  <li className="active last">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/">
                      Recettes Santé
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-19">
            <h1>Recettes pour une alimentation équilibrée</h1>
            <h4>Milk-shake aux fruits des bois et citron vert</h4>
            <div className="row">
              <div className="eight columns">
                <p>
                  <strong>Ingrédients pour 4 portions:</strong>
                </p>
                <ul>
                  <li>100 g de myrtilles</li>
                  <li>50 g de cassis</li>
                  <li>400 ml de lait de soja (par ex. avec calcium)</li>
                  <li>1 pincée de cannelle</li>
                  <li>1 citron vert non traité (écorce, jus)</li>
                  <li>2 CS de miel</li>
                  <li>Un peu de mélisse citronnée fraîche</li>
                </ul>
                <p>
                  <strong>Temps nécessaire:</strong>
                  <br />
                  Préparatifs - 15 min
                  <br />
                  Cuisson/refroidissement - 0 min
                  <br />
                  Préparation - 15 min
                </p>
              </div>
              <div className="four columns">
                <img
                  alt
                  src="/assets/files/pages/bessenshake.jpeg"
                  style={{
                    width: "220px",
                    height: "330px"
                  }}
                />
              </div>
            </div>
            <p>
              <strong>Préparation</strong>
              <strong>:</strong>
              <br />
              Rincez rapidement les fruits à l’eau froide, triez-les puis
              laissez-les égoutter sur un linge. Mélangez les baies avec le lait
              de soja très frais, la cannelle, le jus du citron vert et le miel.
              Réduisez-les en purée avec un mixer plongeant ou dans un mixer.
              Rincez la mélisse citronnée, puis égouttez-la. Effeuillez-la.
              Répartissez le milk-shake dans deux verres. Décorez avec de
              l’écorce de citron vert et de la mélisse citronnée.
            </p>
            <h4>Carpaccio d’asperges et blanc de dinde</h4>
            <div className="row">
              <div className="eight columns">
                <p>
                  <strong>Ingrédients pour 4 portions:</strong>
                </p>
                <ul>
                  <li>300 g de blanc de dinde en tranches</li>
                  <li>150 g d’asperges vertes</li>
                  <li>150 g d’asperges blanches</li>
                  <li>50 g de tomates cocktail</li>
                  <li>30 g de roquette, déchirée</li>
                  <li>20 g de moutarde douce</li>
                  <li>1 CC de poivre rouge en grains</li>
                  <li>50 ml d’huile d’olive</li>
                  <li>1 branche de cerfeuil</li>
                  <li>Sel &amp; poivre du moulin</li>
                  <li>Jus d’un petit citron</li>
                </ul>
                <p>
                  <strong>Temps nécessaire</strong>
                  <strong>:</strong>
                  <br />
                  Préparatifs - 40 min.
                  <br />
                  Cuisson/refroidissement - 0 min.
                  <br />
                  Préparation - 40 min.
                </p>
              </div>
              <div className="four columns">
                <img
                  alt
                  src="/assets/files/pages/spargelcarpacio.jpeg"
                  style={{
                    width: "250px",
                    height: "248px"
                  }}
                />
              </div>
            </div>
            <p>
              <strong>Préparation</strong>
              <strong>:</strong>
              <br />
              Epluchez les asperges blanches et vertes. Coupez-en les extrémités
              et faites-les cuire à l’eau salée jusqu’à ce qu’elles soient
              croquantes. Rincez-les à l’eau froide. Réservez les asperges au
              congélateur pendant 30 minutes. Coupez les asperges gelées en
              tranches très fines, à la manière d’un carpaccio, puis salez et
              poivrez-les. Lavez les tomates et coupez-les en quatre. Coupez
              également le blanc de dinde Gourmet en fines tranches, puis
              ajoutez celles-ci aux asperges. Faites mariner dans le jus de
              citron et l’huile d’olive. Décorez avec du cerfeuil et quelques
              grains de poivre.
              <br />
              Valeurs nutritives par portion : 18 g de protéines, 14 g de
              lipides, 3 g de glucides, 218 kcal
            </p>
            <h4>Filet de truite saumonée aux légumes</h4>
            <div className="row">
              <div className="eight columns">
                <p>
                  <strong>Ingrédients pour 4 portions</strong>
                  <strong>:</strong>
                </p>
                <ul>
                  <li>800 g de filets de truite saumonée</li>
                  <li>2 tomates</li>
                  <li>1 petite courgette</li>
                  <li>200 g de champignons de Paris</li>
                  <li>1/2 céleri-branche (petit)</li>
                  <li>Sel de mer</li>
                  <li>Poivre du moulin</li>
                  <li>1 CS d’huile de noix</li>
                  <li>Persil</li>
                </ul>
                <p>
                  <strong>Temps nécessaire</strong>
                  <strong>:</strong>
                  <br />
                  Préparatifs - 30 min.
                  <br />
                  Cuisson/refroidissement - 0 min.
                  <br />
                  Préparation - 30 min.
                </p>
              </div>
              <div className="four columns">
                <img
                  alt
                  src="/assets/files/pages/Saiblingfilet.jpeg"
                  style={{
                    width: "220px",
                    height: "293px"
                  }}
                />
              </div>
            </div>
            <p>
              <strong>Préparation</strong>
              <strong>:</strong>
              <br />
              Lavez et nettoyez les légumes et le persil. Coupez les tomates en
              huit, la courgette et les champignons en tranches d’environ 0,5 cm
              d’épaisseur, et le céleri-branche en tronçons de 1–2 cm de long.
              Hachez grossièrement le persil. Mélangez tous les légumes coupés
              et le persil dans un plat, puis ajoutez un peu de sel de mer et de
              poivre du moulin. Badigeonnez d’huile de noix 4 feuilles
              d’aluminium de 30 x 50 cm. Disposez sur chacune d’elles un filet
              d’omble chevalier, puis épicez également les filets. Ajoutez un
              quart du mélange de légumes sur chaque filet, puis enveloppez
              fermement poisson et légumes dans la feuille d’aluminium. Placez
              les papillotes sur le gril pendant 15–20 minutes environ, à
              chaleur modérée.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
